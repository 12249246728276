import React, { ComponentProps, FC, memo } from 'react';

const UploadLogo: FC<ComponentProps<'svg'>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 27.5V31.25C5 32.2446 5.39509 33.1984 6.09835 33.9016C6.80161 34.6049 7.75544 35 8.75 35H31.25C32.2446 35 33.1984 34.6049 33.9016 33.9016C34.6049 33.1984 35 32.2446 35 31.25V27.5M12.5 12.5L20 5M20 5L27.5 12.5M20 5V27.5"
        stroke="#333333"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(UploadLogo);
