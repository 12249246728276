import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import PlusLogo from '@components/logos/PlusLogo';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HEAT_UNITS } from '@/constants';
import { HeatSourceItem as HeatSourceItemType } from '@components/custom-flows/heat-source/types';
import { MODEL } from '@components/custom-flows/heat-source/contants';
import FlowDescription from '@components/FlowDescription';
import HeatSourceItem from './HeatSourceItem';
import { Field } from '@/types';

const MAX_SOURCES = 10;

const generateNewItem = (id_heat: number) => {
  return {
    id_heat,
    source_heat: '',
    total_consumption_heat: null,
    unit_heat_amount: HEAT_UNITS[0],
    unit_heat_cost: HEAT_UNITS[0],
    cost_unit_heat: null,
    isGenerateProcessHeat: false,
  };
};

const HeatSource: FC<Field> = ({ label, description }) => {
  const { control, trigger, setValue } = useFormContext<{ heat_information: HeatSourceItemType[] }>();

  const { fields, append, remove } = useFieldArray<{ heat_information: HeatSourceItemType[] }, 'heat_information'>({
    control,
    name: MODEL,
  });

  const [openIndex, setOpenIndex] = useState<number | null>(fields.at(-1)?.id_heat ?? null);

  const handleToggle = (id: number) => {
    setOpenIndex(openIndex === id ? null : id);
  };

  const addNewItem = useCallback(() => {
    const lastId = fields.at(-1)?.id_heat;
    const newId = lastId ? lastId + 1 : 1;

    setOpenIndex(newId);
    return generateNewItem(newId);
  }, [fields]);

  useEffect(() => {
    if (!fields.length) {
      setValue(MODEL, [addNewItem()]);
    }
  }, [addNewItem, fields]);

  const addHeatSource = useCallback(async () => {
    const isValid = await trigger();

    if (isValid) {
      append(addNewItem());
    }
  }, [append, addNewItem]);

  return (
    <div className="flex flex-col gap-6">
      {label && <FlowDescription>{label}</FlowDescription>}
      <FlowDescription>
        Bitte geben Sie uns mehr Informationen zu Ihren Wärmeerzeugern. (Max. {MAX_SOURCES}){' '}
      </FlowDescription>
      {fields?.map(({ id_heat, id }, index) => {
        return (
          <HeatSourceItem
            key={id}
            index={index}
            onRemove={() => remove(index)}
            open={openIndex === id_heat}
            onToggle={() => handleToggle(id_heat)}
            removable={fields.length > 1}
          />
        );
      })}

      {fields.length === MAX_SOURCES ? (
        <FlowDescription>Sie haben das Maximum an möglichen Wärmeerzeugern erreicht.</FlowDescription>
      ) : (
        <button
          type="button"
          onClick={addHeatSource}
          className={
            'py-4 px-8 flex items-center gap-3 justify-between text-base text-gray-500 font-semibold bg-white rounded-md'
          }
        >
          <span>Weiteren Wärmeerzeuger hinzufügen</span>

          <div className="flex items-center justify-center size-12 border border=[var(--grey-scale-300)] rounded-md">
            <PlusLogo />
          </div>
        </button>
      )}
    </div>
  );
};

export default memo(HeatSource);
