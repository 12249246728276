import { memo, useEffect } from 'react';
import { useLottie } from 'lottie-react';
import checkmarkAnimation from '@/assets/checkmark-animation.json';

const CheckmarkAnimation = () => {
  const options = {
    animationData: checkmarkAnimation,
    loop: false,
  };

  const { View, setSpeed } = useLottie(options, { width: 100, margin: 'auto' });

  useEffect(() => {
    setSpeed(0.5);
  }, []);

  return View;
};

export default memo(CheckmarkAnimation);
