/*
array of pages, questions, in prompt order.
this is mostly JSON but defined in JS to permit logic in page handling.
*/

import fields from '@/Gridty-Fields';
import dropdowns from '@/Gridty-Dropdowns';
import { API } from '@constants/api';
import { ConfigType, Field } from '@/types';
import { findPageIndex, generatedPages } from '@/config/helpers';
const pages = Array.from(generatedPages(fields as Field[], dropdowns).values()) as ConfigType['pages'];

export const skipRules = {
  knows_grid_connection: (data: any) => {
    return findPageIndex({
      redirectPage: 'postcode_asset',
      isNextPageAccessible: data.transient.knows_grid_connection,
      pages,
    });
  },
  has_loadprofile: (data: any) => {
    return findPageIndex({
      redirectPage: 'load_profile',
      isNextPageAccessible: data.transient.has_loadprofile,
      pages,
    });
  },
  load_profile_upload: (data: any) => {
    return findPageIndex({
      redirectPage: 'knows_grid_connection',
      isNextPageAccessible:
        !data.load_profile_upload?.length ||
        data.load_profile_upload.some((f: { isError: boolean; uploaded: boolean }) => f.isError && !f.uploaded),
      pages,
    });
  },
  is_heat_required: (data: any) => {
    return findPageIndex({
      redirectPage: 'has_existing_generators',
      isNextPageAccessible: data.transient.is_heat_required,
      pages,
    });
  },
  has_existing_generators: (data: any) => {
    return findPageIndex({
      redirectPage: 'has_add_area',
      isNextPageAccessible: data.transient.has_existing_generators,
      pages,
    });
  },
  has_add_area: (data: any) => {
    return findPageIndex({
      redirectPage: 'wants_charging',
      isNextPageAccessible: data.transient.has_add_area,
      pages,
    });
  },
  wants_charging: (data: any) => {
    return findPageIndex({
      redirectPage: 'address_information',
      isNextPageAccessible: data.transient.wants_charging,
      pages,
    });
  },
  has_public_parking: (data: any) => {
    return findPageIndex({
      redirectPage: 'private_charging',
      isNextPageAccessible: data.transient.has_public_parking,
      pages,
    });
  },
  existing_generators: (data: any) => {
    const isNextPageAccessible = data.existing_generators.some((i: any) => {
      const hasFilesUploaded = i.gen_load_profile_upload?.length > 0 || i.gen_load_profile_upload_second?.length > 0;

      if (!hasFilesUploaded) {
        return true;
      }

      return [...i.gen_load_profile_upload, ...i.gen_load_profile_upload_second].some(f => f.isError);
    });

    return findPageIndex({
      redirectPage: 'has_add_area',
      isNextPageAccessible: isNextPageAccessible,
      pages,
    });
  },
};

const Config: ConfigType = {
  report_api_url: API.calculations.gridty,
  pages,
  skipRules,
};

export default Config;
