import React, { FC, memo } from 'react';
import classNames from 'classnames';

interface Props {
  error?: string | null;
  className?: string;
}

const InputError: FC<Props> = ({ error, className }) => {
  return (
    <div
      className={classNames('text-sm text-error peer-[&:not(:placeholder-shown):not(:focus):invalid]:block', {
        [className!]: className,
      })}
    >
      {error || ' '}
    </div>
  );
};

export default memo(InputError);
