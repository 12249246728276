import React, { FC, memo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';
import Dropdown from '@components/inputs/Dropdown';

interface Props extends Field {
  className?: string;
  onChange?: (value: string) => void;
}

const Select: FC<Props> = ({ className, onChange, ...props }) => {
  return (
    <FieldController
      name={`${props.model}.${props.name}`}
      q={props}
      type={props.type}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputLabel q={props} className={className}>
            <Dropdown
              {...field}
              onChange={value => {
                field.onChange(value);
                onChange?.(value);
              }}
              error={!!error}
              options={props.options}
            />
            <div className="mt-2 mb-2">{error && <InputError error={error.message} />}</div>
          </InputLabel>
        );
      }}
    />
  );
};

export default memo(Select);
