import { HEAT_UNITS } from '@/constants';

export const MODEL = 'heat_information';

export const HEAT_SOURCE_FORM = {
  source_heat: {
    name: 'source_heat',
    type: 'select',
    is_required: true,
    placeholder: 'Bitte wählen Sie',
    label: 'Wie erzeugen Sie die Wärme?',
    description: 'Description',
    options: [
      'Erdgas',
      'Flüssiggas',
      'Öl',
      'Wärmepumpe',
      'Solarthermie',
      'Fernwärme',
      'Propan',
      'Butan',
      'Elektrische Heizung (Direkumwandlung)',
      'Geothermie',
      'Biomasse',
      'Wasserstoff',
      'Holzpellets',
      'Diesel',
      'Biodiesel',
    ],
  },
  total_consumption_heat: {
    name: 'total_consumption_heat',
    type: 'float',
    is_required: true,
    min: 0,
    label: 'Wärmemenge und Einheit',
    unitName: 'unit_heat_amount',
    options: HEAT_UNITS,
  },
  cost_unit_heat: {
    name: 'cost_unit_heat',
    type: 'float',
    is_required: true,
    min: 0,
    label: 'Wärmepreis und Einheit',
    unitName: 'unit_heat_cost',
    options: HEAT_UNITS,
    unit: 'Cent /',
  },
  isGenerateProcessHeat: {
    name: 'isGenerateProcessHeat',
    type: 'switch',
    is_required: false,
    label: 'Erzeugen Sie damit auch Prozesswärme?',
  },
  max_temp_process_heat: {
    name: 'max_temp_process_heat',
    type: 'integer',
    is_required: true,
    min: 0,
    label: 'Maximaltemperatur',
    unit: '°C',
  },
  max_power_process_heat: {
    name: 'max_power_process_heat',
    type: 'integer',
    is_required: true,
    min: 0,
    label: 'Maximalleistung',
    unit: 'kW',
  },
  fluid_type_process_heat: {
    name: 'fluid_type_process_heat',
    type: 'select',
    is_required: true,
    label: 'Wärmeträgermedium',
    description: 'Description',
    options: ['Wasser', 'Dampf', 'Thermo-öl', 'Andere'],
  },
};
