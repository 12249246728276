import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import PlusLogo from '@components/logos/PlusLogo';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HEAT_UNITS } from '@/constants';
import { GeneratorsSourceItem as GeneratorsSourceItemType } from '@components/custom-flows/generators-source/types';
import { MODEL } from '@components/custom-flows/generators-source/contants';
import FlowDescription from '@components/FlowDescription';
import GeneratorsSourceItem from './GeneratorsSourceItem';
import { Field } from '@/types';

const MAX_SOURCES = 10;

const generateNewItem = (id_gen: number) => {
  return {
    id_gen,
    type_gen: '',
    max_power_gen: null,
    total_yield_gen: null,
    startfeedin_gen: '',
    self_use_gen: false,
    eeg_subsidy_old_gen: null,
  };
};

const GeneratorsSource: FC<Field> = ({ label, description }) => {
  const { control, trigger, setValue } = useFormContext<{ existing_generators: GeneratorsSourceItemType[] }>();

  const { fields, append, remove } = useFieldArray<
    { existing_generators: GeneratorsSourceItemType[] },
    'existing_generators'
  >({
    control,
    name: MODEL,
  });

  const [openIndex, setOpenIndex] = useState<number | null>(fields.at(-1)?.id_gen ?? null);

  const handleToggle = (id: number) => {
    setOpenIndex(openIndex === id ? null : id);
  };

  const addNewItem = useCallback(() => {
    const lastId = fields.at(-1)?.id_gen;
    const newId = lastId ? lastId + 1 : 1;

    setOpenIndex(newId);
    return generateNewItem(newId);
  }, [fields]);

  useEffect(() => {
    if (!fields.length) {
      setValue(MODEL, [addNewItem()]);
    }
  }, [addNewItem, fields]);

  const addHeatSource = useCallback(async () => {
    const isValid = await trigger();

    if (isValid) {
      append(addNewItem());
    }
  }, [append, addNewItem]);

  return (
    <div className="flex flex-col gap-6">
      {label && <FlowDescription>{label}</FlowDescription>}
      <FlowDescription>
        Bitte füllen Sie hier Informationen zu Ihren Erzeugungsanlagen ein (Max. {MAX_SOURCES}){' '}
      </FlowDescription>
      {fields?.map(({ id_gen, id }, index) => {
        return (
          <GeneratorsSourceItem
            key={id}
            index={index}
            onRemove={() => remove(index)}
            open={openIndex === id_gen}
            onToggle={() => handleToggle(id_gen)}
            removable={fields.length > 1}
          />
        );
      })}

      {fields.length === MAX_SOURCES ? (
        <FlowDescription>Sie haben das Maximum an möglichen Erzeugungsanlagen erreicht.</FlowDescription>
      ) : (
        <button
          type="button"
          onClick={addHeatSource}
          className={
            'py-4 px-8 flex items-center gap-3 justify-between text-base text-gray-500 font-semibold bg-white rounded-md'
          }
        >
          <span>Weitere Erzeugungsanlage hinzufügen</span>

          <div className="flex items-center justify-center size-12 border border=[var(--grey-scale-300)] rounded-md">
            <PlusLogo />
          </div>
        </button>
      )}
    </div>
  );
};

export default memo(GeneratorsSource);
