import { useEffect, useState } from 'react';
import { QueryDataHookResult } from '@/types';
import { METHODS } from '@constants/api';
import { VITE_NODE_ENV } from '@utils/getEnv';

interface FileResponse {
  message: string;
}

const useFileUploader = ({
  file,
  model,
  uuid,
  upload_id,
  skip,
  onError,
  onSuccess,
}: {
  file: File;
  model: string;
  uuid: string;
  upload_id?: string;
  skip?: boolean;
  onError?: (message: string) => void;
  onSuccess?: () => void;
}): QueryDataHookResult<FileResponse> => {
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [data, setData] = useState<FileResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (!skip) {
      const uploadFile = async () => {
        setLoading(true);
        setIsFetched(true);
        setError(null);

        const formData = new FormData();
        formData.append('file', file);

        try {
          const params = new URLSearchParams({ uuid, ...(upload_id && { upload_id }) });
          const endpoint = `/${model}/?${params}`;

          const result = await fetch(`${VITE_NODE_ENV['VITE_APP_API_FILE_URL']}${endpoint}`, {
            method: METHODS.POST,
            signal,
            headers: {
              Authorization: `Token ${VITE_NODE_ENV['VITE_FILE_AUTH_TOKEN']}`,
            },
            body: formData,
          });

          const response = await result.json();

          if (!result.ok) {
            onError?.(response.error);
            throw new Error(response.error);
          }

          setData(response);
          onSuccess?.();
          setError(null);
        } catch (error) {
          if ((error as { name: string })?.name !== 'AbortError') {
            const message = (error as Error).message || 'Something went wrong';
            setError(message);
            onError?.(message);
          }
        } finally {
          setLoading(false);
        }
      };

      uploadFile();
    }

    return () => {
      controller.abort();
    };
  }, [file, model, uuid, skip]);

  return { data: data as FileResponse, loading, error, isFetched };
};

export default useFileUploader;
