import React from 'react';

const PdfFileLogo = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5 23.75V19.375C32.5 17.8832 31.9074 16.4524 30.8525 15.3975C29.7976 14.3426 28.3668 13.75 26.875 13.75H24.375C23.8777 13.75 23.4008 13.5525 23.0492 13.2008C22.6975 12.8492 22.5 12.3723 22.5 11.875V9.375C22.5 7.88316 21.9074 6.45242 20.8525 5.39752C19.7976 4.34263 18.3668 3.75 16.875 3.75H13.75M17.5 3.75H9.375C8.34 3.75 7.5 4.59 7.5 5.625V34.375C7.5 35.41 8.34 36.25 9.375 36.25H30.625C31.66 36.25 32.5 35.41 32.5 34.375V18.75C32.5 14.7718 30.9196 10.9564 28.1066 8.1434C25.2936 5.33035 21.4782 3.75 17.5 3.75Z"
        stroke="#94A3B8"
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="1.5" y="20.5" width="22" height="12" rx="2" fill="#FF4D4F" />
      <path
        d="M4.27875 29.5V23.9H6.83875C7.35075 23.9 7.79075 23.9827 8.15875 24.148C8.53208 24.3133 8.82008 24.5533 9.02275 24.868C9.22542 25.1773 9.32675 25.5453 9.32675 25.972C9.32675 26.3933 9.22542 26.7587 9.02275 27.068C8.82008 27.3773 8.53208 27.6173 8.15875 27.788C7.79075 27.9533 7.35075 28.036 6.83875 28.036H5.15875L5.86275 27.348V29.5H4.27875ZM5.86275 27.516L5.15875 26.788H6.74275C7.07342 26.788 7.31875 26.716 7.47875 26.572C7.64408 26.428 7.72675 26.228 7.72675 25.972C7.72675 25.7107 7.64408 25.508 7.47875 25.364C7.31875 25.22 7.07342 25.148 6.74275 25.148H5.15875L5.86275 24.42V27.516ZM10.0991 29.5V23.9H12.7471C13.3657 23.9 13.9097 24.0147 14.3791 24.244C14.8484 24.4733 15.2137 24.796 15.4751 25.212C15.7417 25.628 15.8751 26.124 15.8751 26.7C15.8751 27.2707 15.7417 27.7667 15.4751 28.188C15.2137 28.604 14.8484 28.9267 14.3791 29.156C13.9097 29.3853 13.3657 29.5 12.7471 29.5H10.0991ZM11.6831 28.236H12.6831C13.0031 28.236 13.2804 28.1773 13.5151 28.06C13.7551 27.9373 13.9417 27.7613 14.0751 27.532C14.2084 27.2973 14.2751 27.02 14.2751 26.7C14.2751 26.3747 14.2084 26.0973 14.0751 25.868C13.9417 25.6387 13.7551 25.4653 13.5151 25.348C13.2804 25.2253 13.0031 25.164 12.6831 25.164H11.6831V28.236ZM18.1804 26.356H20.7644V27.58H18.1804V26.356ZM18.2924 29.5H16.7084V23.9H21.1004V25.124H18.2924V29.5Z"
        fill="white"
      />
    </svg>
  );
};

export default PdfFileLogo;
