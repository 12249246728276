import React, { FC, ForwardedRef, forwardRef, memo } from 'react';
import { Field } from '@/types';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';

interface Props extends Field {
  onChange: (value: string) => void;
  value: string;
  error?: boolean;
  className?: string;
}

const numericProps: Record<string, Record<string, any>> = {
  integer: {
    thousandsSeparator: '',
  },
};

const NumericInput: FC<Props> = forwardRef((props, ref: ForwardedRef<HTMLInputElement>) => {
  const { type, name, onChange, value, error, className, ...q } = props;
  const range = q.max ? `${q.min ?? 0}-${q.max}` : undefined;
  const onlyMin = q.min;
  const placeholder = q.placeholder ?? range ?? onlyMin ?? '';

  return (
    <IMaskInput
      radix=","
      mapToRadix={['.']}
      mask={Number}
      thousandsSeparator="."
      unmask={true}
      onAccept={(value: string) => onChange(value)}
      {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
      className={classNames('w-full rounded border border-base-300 p-3 mt-2 text-neutral-800', {
        [className!]: className,
      })}
      placeholder={placeholder.toString()}
      pattern={q.pattern}
      padFractionalZeros={false}
      normalizeZeros={false}
      value={value}
      inputRef={ref}
      {...numericProps[type]}
    />
  );
});

export default memo(NumericInput);
