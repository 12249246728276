import React, { ReactNode, useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import NiceModal from '@ebay/nice-modal-react';
import CloseLogo from '@components/logos/CloseLogo';

interface Props {
  title: string;
  description: ReactNode;
}

const ConfirmRemoveModal = ({ title, description }: Props) => {
  const { remove, resolve } = useModal();

  const onConfirm = useCallback(() => {
    resolve();
    remove();
  }, [resolve, resolve]);

  return (
    <div className="fixed inset-0	flex  z-10">
      <div className="absolute inset-0 bg-accent opacity-50	-z-10"></div>

      <div className="min-w-[494px] flex flex-col px-4 pt-4 pb-10 rounded-2xl bg-white m-auto">
        <button type="button" className="self-end text-right" onClick={remove}>
          <CloseLogo />
        </button>

        <div className="flex flex-col gap-4  text-center">
          <h2 className=" text-3xl font-bold text-center">{title}</h2>
          <div className="text-[18px] text-accent font-semibold">{description}</div>
        </div>
        <div className="flex justify-center gap-4 mt-10">
          <button
            className="btn-modal border-[var(--grey-scale-300)] text-accent hover:bg-[var(--grey-scale-100)] transition"
            type="button"
            onClick={remove}
          >
            Zurück
          </button>
          <button
            className="btn-modal border-error bg-error text-white hover:opacity-90"
            type="button"
            onClick={onConfirm}
          >
            Entfernen
          </button>
        </div>
      </div>
    </div>
  );
};

export default NiceModal.create(ConfirmRemoveModal);
