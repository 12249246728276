import React, { memo } from 'react';

const SurveySubtitle = () => (
  <div
    className="survey-subtitle flex items-baseline  justify-between flex-wrap gap-2 text-end max-sm:text-start"
    style={{ paddingBottom: '1rem', borderBottom: '2px solid var(--subtitle-color)' }}
  >
    <span className="inline-block flex-grow">In wenigen Schritten zu Ihrer Energie-Analyse</span>
  </div>
);

export default memo(SurveySubtitle);
