import React, { FC, ForwardedRef, forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import ExpandArrow from '@components/logos/ExpandArrow';
import classNames from 'classnames';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  options?: string[];
  error?: boolean;
  hidePlaceholder?: boolean;
  className?: string;
  arrowClassName?: string;
}

const Dropdown: FC<Props> = forwardRef(
  (
    { value, className, arrowClassName, onChange, options, hidePlaceholder = false, error, ...props },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);

    const toggleDropdown = useCallback(() => setIsOpen(current => !current), []);

    return (
      <div className="relative" ref={ref}>
        <button
          {...props}
          type="button"
          className={classNames(
            'truncate flex justify-between items-center w-full rounded border border-base-300 p-3 mt-2 text-neutral-800 font-semibold bg-white text-left',
            { [className!]: !!className },
          )}
          {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
          onClick={toggleDropdown}
        >
          <span className="truncate">{value || (!hidePlaceholder && '--Bitte wählen Sie--')}</span>
          <ExpandArrow className={classNames('min-w-6', { [arrowClassName!]: !!arrowClassName })} />
        </button>

        {!!options?.length && (
          <div
            className={`w-full max-h-[300px] max-md:max-h-[193px] overflow-y-auto top-3 -left-3 absolute right-0 z-10 mt-2 origin-top-right rounded bg-white  transition ease-out duration-100  border-[var(--grey-scale-300)] border-[1px] shadow-box ${
              isOpen ? 'transform opacity-100 scale-100 z-10' : 'transform h-0 opacity-0 scale-95 -z-10'
            }`}
            role="menu"
            ref={dropdownRef}
          >
            <ul>
              {!hidePlaceholder && (
                <li
                  className="block px-4 py-1 text-accent text-[16px] font-semibold cursor-pointer hover:bg-[var(--grey-scale-100)]"
                  role="menuitem"
                  onClick={() => {
                    onChange('');
                    setIsOpen(false);
                  }}
                >
                  --Bitte wählen Sie--
                </li>
              )}

              {options.map(o => (
                <li
                  key={o}
                  className="block px-4 py-1 text-accent text-[16px] font-semibold cursor-pointer hover:bg-[var(--grey-scale-100)]"
                  role="menuitem"
                  onClick={() => {
                    onChange(o);
                    setIsOpen(false);
                  }}
                >
                  {o}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  },
);

export default memo(Dropdown);
