import React, { ComponentProps, FC, memo } from 'react';
import classNames from 'classnames';

const AppLogo: FC<ComponentProps<'div'>> = ({ className, style, ...props }) => {
  return (
    <div
      className={classNames('mt-3 max-md:h-12 max-md:w-[150px] max-md:m-0 w-full h-[50px]', {
        [className!]: className,
      })}
      style={{
        backgroundImage: 'var(--app-logo)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        ...style,
      }}
    />
  );
};

export default memo(AppLogo);
