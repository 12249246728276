import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import ThemeProvider from './context/ThemeProvider';
import * as Sentry from '@sentry/react';
import NiceModal from '@ebay/nice-modal-react';
import { isDev } from '@utils/getEnv';

if (!isDev) {
  Sentry.init({
    dsn: 'https://290cb2bc6d1d9e6d7bd55ca69d141113@o4508137418457088.ingest.de.sentry.io/4508139534155856',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider>
      <NiceModal.Provider>
        <App />
      </NiceModal.Provider>
    </ThemeProvider>
  </StrictMode>,
);
