import React, { memo } from 'react';

const FireLogo = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.6043 8.68997C28.4791 9.97459 30.826 12.2067 32.2531 15.0135C33.6801 17.8203 34.1008 21.0318 33.4449 24.1115C32.789 27.1912 31.0963 29.9525 28.6494 31.9343C26.2025 33.916 23.1497 34.9982 20.001 35C17.3074 35.0002 14.673 34.2092 12.425 32.7252C10.1769 31.2413 8.41431 29.1297 7.35591 26.6527C6.29751 24.1757 5.98997 21.4424 6.47147 18.7921C6.95296 16.1419 8.2023 13.6914 10.0643 11.745C11.3467 13.5374 13.0394 14.9969 15.001 16.0016C15.0354 13.7994 15.5552 11.6318 16.5235 9.65346C17.4917 7.67515 18.8846 5.93484 20.6027 4.55664C21.9139 6.31529 23.6288 7.73291 25.6027 8.68997H25.6043Z"
        stroke="#37C9EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6043 8.68997C28.4791 9.97459 30.826 12.2067 32.2531 15.0135C33.6801 17.8203 34.1008 21.0318 33.4449 24.1115C32.789 27.1912 31.0963 29.9525 28.6494 31.9343C26.2025 33.916 23.1497 34.9982 20.001 35C17.3074 35.0002 14.673 34.2092 12.425 32.7252C10.1769 31.2413 8.41431 29.1297 7.35591 26.6527C6.29751 24.1757 5.98997 21.4424 6.47147 18.7921C6.95296 16.1419 8.2023 13.6914 10.0643 11.745C11.3467 13.5374 13.0394 14.9969 15.001 16.0016C15.0354 13.7994 15.5552 11.6318 16.5235 9.65346C17.4917 7.67515 18.8846 5.93484 20.6027 4.55664C21.9139 6.31529 23.6288 7.73291 25.6027 8.68997H25.6043Z"
        stroke="#37C9EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0014 30.0004C21.5892 30.003 23.1185 29.4012 24.2787 28.3171C25.4389 27.2331 26.143 25.7481 26.248 24.1637C26.3531 22.5794 25.8511 21.0145 24.8442 19.7868C23.8372 18.5591 22.4007 17.7607 20.8264 17.5537C19.1054 19.0938 17.9715 21.183 17.6181 23.4654C16.3341 23.1507 15.1256 22.5836 14.0631 21.797C13.7542 22.7359 13.6723 23.7347 13.8243 24.7113C13.9763 25.6879 14.3577 26.6146 14.9373 27.4153C15.5168 28.2159 16.278 28.8676 17.1584 29.317C18.0387 29.7664 19.013 30.0006 20.0014 30.0004Z"
        stroke="#37C9EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0014 30.0004C21.5892 30.003 23.1185 29.4012 24.2787 28.3171C25.4389 27.2331 26.143 25.7481 26.248 24.1637C26.3531 22.5794 25.8511 21.0145 24.8442 19.7868C23.8372 18.5591 22.4007 17.7607 20.8264 17.5537C19.1054 19.0938 17.9715 21.183 17.6181 23.4654C16.3341 23.1507 15.1256 22.5836 14.0631 21.797C13.7542 22.7359 13.6723 23.7347 13.8243 24.7113C13.9763 25.6879 14.3577 26.6146 14.9373 27.4153C15.5168 28.2159 16.278 28.8676 17.1584 29.317C18.0387 29.7664 19.013 30.0006 20.0014 30.0004Z"
        stroke="#37C9EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(FireLogo);
