import React, { FC, memo } from 'react';
import RemoveLogo from '@components/logos/RemoveLogo';
import ConfirmModal from '@components/modals/ConfirmRemoveModal';
import NiceModal from '@ebay/nice-modal-react';

interface Props {
  onRemove: () => void;
  block: string;
}

const RemoveButton: FC<Props> = ({ block, onRemove }) => {
  const openDialog = async () => {
    await NiceModal.show(ConfirmModal, {
      title: 'Sind Sie sicher?',
      description: <p className="whitespace-pre">{`Sie entfernen den folgenden Eintrag:\n ${block}`}</p>,
    });
    onRemove();
  };

  return (
    <button
      className="mt-8 flex items-center gap-2 p-1 font-semibold text-accent text-base leading-none rounded-md transition hover:bg-[var(--grey-scale-100)]"
      type="button"
      onClick={openDialog}
    >
      <RemoveLogo className="*:stroke-accent" />
      <span>Eintrag entfernen</span>
    </button>
  );
};

export default memo(RemoveButton);
