import React, { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { HeatSourceItem } from '@components/custom-flows/heat-source/types';
import Select from '@components/form/Select';
import { HEAT_SOURCE_FORM, MODEL } from '@components/custom-flows/heat-source/contants';
import CombinedInput from '@components/form/CombinedInput';
import ControlledSwitch from '@components/form/ControlledSwitch';

interface Props {
  index: number;
}

const ProcessHeatData: FC<Props> = ({ index }) => {
  const { watch } = useFormContext<{ heat_information: HeatSourceItem[] }>();
  const isGenerateProcessHeat = watch(`heat_information.${index}.isGenerateProcessHeat`);

  return (
    <div>
      <ControlledSwitch
        page={''}
        model={MODEL}
        category={''}
        className="mb-8"
        {...HEAT_SOURCE_FORM.isGenerateProcessHeat}
        name={`${index}.${HEAT_SOURCE_FORM.isGenerateProcessHeat.name}`}
      />

      {isGenerateProcessHeat && (
        <>
          <CombinedInput
            page={''}
            model={MODEL}
            category={''}
            className="input-short"
            {...HEAT_SOURCE_FORM.max_temp_process_heat}
            name={`${index}.${HEAT_SOURCE_FORM.max_temp_process_heat.name}`}
          />

          <CombinedInput
            page={''}
            model={MODEL}
            category={''}
            className="input-short"
            {...HEAT_SOURCE_FORM.max_power_process_heat}
            name={`${index}.${HEAT_SOURCE_FORM.max_power_process_heat.name}`}
          />

          <Select
            category={''}
            page={''}
            model={MODEL}
            {...HEAT_SOURCE_FORM.fluid_type_process_heat}
            name={`${index}.${HEAT_SOURCE_FORM.fluid_type_process_heat.name}`}
          />
        </>
      )}
    </div>
  );
};

export default memo(ProcessHeatData);
