import React, { FC, PropsWithChildren } from 'react';
import ThemeSwitcher from '@shared/ThemeSwitcher';
import GridContainer from '@components/GridContainer';
import AppLogo from '@/components/AppLogo';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const MainContainer: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return (
    <GridContainer
      className={classNames('bg-primary grow px-4 gap-1 max-md:block max-md:pt-4', { [className!]: className })}
    >
      <AppLogo className="" />

      <div className="container max-md:mx-auto py-8 rounded-xl space-y-6 items-center">{children}</div>
      <ThemeSwitcher />
    </GridContainer>
  );
};

export default MainContainer;
