import React, { ComponentProps, FC, PropsWithChildren } from 'react';

const IconButton: FC<PropsWithChildren<ComponentProps<'button'>>> = ({ children, className, ...props }) => {
  return (
    <button
      className={`p-1 rounded-full flex items-center justify-center transition hover:bg-[var(--grey-scale-50)] ${className || ''}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default IconButton;
