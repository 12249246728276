import React, { memo } from 'react';

const LinearProgress = () => {
  return (
    <div className="w-full bg-[var(--grey-scale-300)] rounded-full h-[3px] relative">
      <div className="animate-linear-progress absolute bg-[var(--theme-icon-color)] h-[3px] rounded-full"></div>
    </div>
  );
};

export default memo(LinearProgress);
