import React, { FC, PropsWithChildren } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Field } from '@/types';
import classNames from 'classnames';

interface Props {
  q: Field;
  className?: string;
  inputClassName?: string;
}

const InputLabel: FC<PropsWithChildren<Props>> = ({ q, children, className, inputClassName }) => {
  return (
    <label key={q.name} htmlFor={q.name} className={classNames('input-label', { [className!]: className })}>
      <span>
        {q.label}
        {q.is_required && '*'}
        {q.description && (
          <span className="tooltip tooltip-right" data-tip={q.description}>
            <InformationCircleIcon className="icon-color" />
          </span>
        )}
      </span>
      <div className={classNames('relative', { [inputClassName!]: inputClassName })}>{children}</div>
    </label>
  );
};

export default InputLabel;
