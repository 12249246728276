import React, { FC, memo } from 'react';
import SettingsLogo from '@components/logos/SettingsLogo';
import HouseLogo from '@components/logos/HouseLogo';
import ProgramsLogo from '@components/logos/ProgramsLogo';
import LightningLogo from '@components/logos/LightningLogo';
import AvatarLogo from '@components/logos/AvatarLogo';
import FireLogo from '@components/logos/FireLogo';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Field } from '@/types';

const LOGOS = {
  'Ort der Anlage': <HouseLogo />,
  Bestandsanlage: <SettingsLogo />,
  Stromverbrauch: <SettingsLogo />,
  'Verfügbare Fläche': <ProgramsLogo />,
  Energieprofil: <LightningLogo />,
  Kundendaten: <AvatarLogo />,
  Warmeversorgung: <FireLogo />,
};

const CATEGORIES_WITH_TOOLTIP = ['Wärmeversorgung'];

interface Props {
  category: string;
  qns?: Field[];
  name: string;
}

const CategoryTitle: FC<Props> = ({ category, name, qns }) => {
  const description = qns?.find(f => f.category === category)?.description;

  return (
    <div className={`mt-12 pt-6`}>
      <div>{LOGOS[category as keyof typeof LOGOS] || <SettingsLogo />}</div>
      <div className=" flex flex-row items-center gap-2">
        <h2 className="font-bold text-[36px]">{category || name}</h2>
        {CATEGORIES_WITH_TOOLTIP.includes(category) && description && (
          <span className="tooltip tooltip-right" data-tip={description}>
            <InformationCircleIcon className="icon-color" />
          </span>
        )}
      </div>
    </div>
  );
};

export default memo(CategoryTitle);
