import React, { FC, PropsWithChildren } from 'react';

interface Props {
  className?: string;
}
const GridContainer: FC<PropsWithChildren<Props>> = ({ className = '', children }) => {
  return (
    <div
      className={`grid grid-cols-[minmax(190px,316px)_minmax(400px,560px)_minmax(190px,316px)] justify-between ${className}`}
    >
      {children}
    </div>
  );
};

export default GridContainer;
