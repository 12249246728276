import React, { FC, memo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { FieldComponentProps } from '@/types';
import { IMaskInput } from 'react-imask';

interface Props extends FieldComponentProps {
  className?: string;
}

const ControlledNumericInput: FC<Props> = ({ className, ...props }) => {
  const { type, name, autoFocus, ...q } = props;
  const range = q.max ? `${q.min ?? 0}-${q.max}` : undefined;
  const onlyMin = q.min;
  const placeholder = q.placeholder ?? range ?? onlyMin ?? '';

  return (
    <FieldController
      name={`${q.model}.${name}`}
      q={q}
      type={type}
      render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => {
        return (
          <InputLabel className={className} q={props}>
            <IMaskInput
              autoFocus={autoFocus}
              radix=","
              mapToRadix={['.']}
              mask={Number}
              thousandsSeparator="."
              unmask={true}
              onAccept={(value: string) => onChange(value)}
              {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
              className="w-full rounded border border-base-300 p-3 mt-2 text-neutral-800"
              placeholder={placeholder.toString()}
              pattern={q.pattern}
              padFractionalZeros={false}
              normalizeZeros={false}
              {...field}
              inputRef={ref}
            />

            {q.unit && <span className="absolute right-2 text-accent mt-[18px]">{q.unit}</span>}

            <div className="mt-2 mb-2 h-1">{error && <InputError error={error.message} />}</div>
          </InputLabel>
        );
      }}
    />
  );
};

export default memo(ControlledNumericInput);
