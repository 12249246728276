import React, { ComponentProps, FC, memo } from 'react';

const RemoveLogo: FC<ComponentProps<'svg'>> = props => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.74 9.00052L14.394 18.0005M9.606 18.0005L9.26 9.00052M19.228 5.79052C19.57 5.84252 19.91 5.89752 20.25 5.95652M19.228 5.79052L18.16 19.6735C18.1164 20.2387 17.8611 20.7667 17.445 21.1518C17.029 21.5368 16.4829 21.7507 15.916 21.7505H8.084C7.5171 21.7507 6.97102 21.5368 6.55498 21.1518C6.13894 20.7667 5.88359 20.2387 5.84 19.6735L4.772 5.79052M19.228 5.79052C18.0739 5.61604 16.9138 5.48362 15.75 5.39352M4.772 5.79052C4.43 5.84152 4.09 5.89652 3.75 5.95552M4.772 5.79052C5.92613 5.61604 7.08623 5.48362 8.25 5.39352M15.75 5.39352V4.47752C15.75 3.29752 14.84 2.31352 13.66 2.27652C12.5536 2.24116 11.4464 2.24116 10.34 2.27652C9.16 2.31352 8.25 3.29852 8.25 4.47752V5.39352M15.75 5.39352C13.2537 5.2006 10.7463 5.2006 8.25 5.39352"
        stroke="#94A3B8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(RemoveLogo);
