import React, { ComponentProps, FC, memo } from 'react';

const UploadFileLogo: FC<ComponentProps<'svg'>> = props => {
  return (
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5 23.75V19.375C32.5 17.8832 31.9074 16.4524 30.8525 15.3975C29.7976 14.3426 28.3668 13.75 26.875 13.75H24.375C23.8777 13.75 23.4008 13.5525 23.0492 13.2008C22.6975 12.8492 22.5 12.3723 22.5 11.875V9.375C22.5 7.88316 21.9074 6.45242 20.8525 5.39752C19.7976 4.34263 18.3668 3.75 16.875 3.75H13.75M17.5 3.75H9.375C8.34 3.75 7.5 4.59 7.5 5.625V34.375C7.5 35.41 8.34 36.25 9.375 36.25H30.625C31.66 36.25 32.5 35.41 32.5 34.375V18.75C32.5 14.7718 30.9196 10.9564 28.1066 8.1434C25.2936 5.33035 21.4782 3.75 17.5 3.75Z"
        stroke="#94A3B8"
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(UploadFileLogo);
