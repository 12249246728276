import React, { FC, memo } from 'react';
import CustomField from '@components/form/CustomField';
import TextInput from '@components/form/TextInput';
import RadioGroup from '@components/form/RadioGroup';
import Select from '@components/form/Select';
import { Field } from '@/types';
import ControlledNumericInput from '@components/form/ControlledNumericInput';
import ControlledSwitch from '@components/form/ControlledSwitch';
import FileUploader from '@components/form/file-uploader/FileUploader';

interface Props {
  inputs: Field[];
}

const FIELDS_MAPPING = {
  number: ControlledNumericInput,
  integer: ControlledNumericInput,
  float: ControlledNumericInput,
  tel: TextInput,
  date: TextInput,
  email: TextInput,
  text: TextInput,
  checkbox: RadioGroup,
  select: Select,
  custom: CustomField,
  array: CustomField,
  switch: ControlledSwitch,
  file: FileUploader,
};

const Form: FC<Props> = ({ inputs }) => {
  return (
    <div className="flex flex-col w-full">
      {inputs.map(({ type, name, model, ...q }: any, i: number) => {
        const Component = FIELDS_MAPPING[type as keyof typeof FIELDS_MAPPING];

        if (!Component) return <div key={q.name}>Unknown type {type}</div>;

        const isFirstComponent = i === 0;

        return <Component autoFocus={isFirstComponent} key={name} name={name} model={model} type={type} {...q} />;
      })}
    </div>
  );
};

export default memo(Form);
