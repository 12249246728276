import React, { FC, HTMLInputTypeAttribute, memo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { FieldComponentProps } from '@/types';

const typeMapper: Partial<Record<HTMLInputTypeAttribute, HTMLInputTypeAttribute>> = {
  number: 'text',
  integer: 'text',
  float: 'text',
};

interface Props extends FieldComponentProps {
  className?: string;
}

const TextInput: FC<Props> = ({ className, ...props }) => {
  const { type, name, autoFocus, ...q } = props;
  const range = q.max ? `${q.min ?? 0}-${q.max}` : undefined;
  const onlyMin = q.min;
  const placeholder = q.placeholder ?? range ?? onlyMin ?? '';

  return (
    <FieldController
      name={`${q.model}.${name}`}
      q={q}
      type={type}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputLabel className={className} q={props}>
            <input
              autoFocus={autoFocus}
              type={typeMapper[type as keyof typeof typeMapper] || type}
              {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
              className="w-full rounded border border-base-300 p-3 mt-2 text-neutral-800"
              placeholder={placeholder.toString()}
              pattern={q.pattern}
              {...field}
              name={name}
            />

            {q.unit && <span className="absolute right-2 text-accent mt-[18px]">{q.unit}</span>}

            <div className="mt-2 mb-2">{error && <InputError error={error.message} />}</div>
          </InputLabel>
        );
      }}
    />
  );
};

export default memo(TextInput);
