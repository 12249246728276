import { Field } from '@/types';

export const generatedPages = (fields: Field[], dropdowns: Record<string, string[]> = {}) =>
  fields.reduce((prev, curr) => {
    const field = {
      ...curr,
      ...(curr.type === 'select' && { options: dropdowns[curr.name] }),
    };

    const currentPage = prev.get(curr.page as string);
    const updatedPage = currentPage
      ? { ...currentPage, qns: [...currentPage.qns, field] }
      : { name: curr.page, category: curr.category, qns: [field] };

    prev.set(curr.page, updatedPage);
    return prev;
  }, new Map<string, any>());

export const findPageIndex = ({
  redirectPage,
  pages,
  isNextPageAccessible,
}: {
  redirectPage: string;
  pages: any[];
  isNextPageAccessible: boolean;
}) => {
  const pageIndex = pages.findIndex(page => page.name === redirectPage);

  return isNextPageAccessible ? -1 : pageIndex;
};
