import { memo, useEffect } from 'react';
import { themeChange } from 'theme-change';
import { VITE_NODE_ENV } from '@utils/getEnv';

const IS_THEME_DROPDOWN_ENABLED = JSON.parse(VITE_NODE_ENV['VITE_ENABLE_THEME_DROPDOWN'] ?? 'false');

const defaultTheme = VITE_NODE_ENV['VITE_THEME'] ?? 'gridty';
localStorage.setItem('theme', defaultTheme);

const ThemeSwitcher = () => {
  useEffect(() => {
    themeChange(false);
  }, []);

  return IS_THEME_DROPDOWN_ENABLED ? (
    <div className="mt-4 justify-self-end">
      <select data-choose-theme className="focus:outline-none bg-base-100  h-10 w-32 rounded-full px-3 border">
        <option value="gridty">Gridty</option>
        <option value="edf">EDF</option>
        <option value="arup">ARUP</option>
      </select>
    </div>
  ) : null;
};

export default memo(ThemeSwitcher);
