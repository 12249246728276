import MainContainer from '@shared/MainContainer';
import SurveySubtitle from '@components/SurveySubtitle';
import CategoryTitle from '@components/CategoryTitle';
import ProgressBar from '@components/ProgressBar';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '@components/form/Form';
import { CURRENT_CONFIG } from '@/config';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowRight from '@components/ArrowRight';
import ChevronBack from '@components/ChevronBack';
import ArrowLeft from '@components/ArrowLeft';
import { initial_daily, initial_monthly } from '@components/custom-flows/load-profile/helpers';
import { v7 as uuidv7 } from 'uuid';
import usePostData from '@hooks/usePostData';
import useGenerateReport from '@hooks/useGenerateReport';
import { getDefaultValues } from '@/helpers/getDefaultValues';
import Report from '@components/Report';
import { useTheme } from '@/context/ThemeProvider';
import GridContainer from '@components/GridContainer';
import { VITE_NODE_ENV } from '@utils/getEnv';
import { useFormLock } from '@/hooks/useFormLock';

const reportFileGenerationEnabled = JSON.parse(VITE_NODE_ENV['VITE_ENABLE_REPORT_GENERATION']);
const pages = CURRENT_CONFIG.pages;

function App() {
  const { theme } = useTheme();
  const [page, setPage] = useState(0);
  const [pageHistory, setPageHistory] = useState<number[]>([]); // Tracks all visited pages
  const [sendModels, { loading: loadingModels, isFetched, error: modelsError }] = usePostData();
  const [generateReport, { data: report, error: reportError }] = useGenerateReport();
  const currentPage = pages[page];

  const form = useForm<Record<string, string | Record<string, any>>>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      uuid: uuidv7(),
      ...getDefaultValues(pages),
      load_profile_upload: [],
      load_profile: {
        daily_profile: initial_daily.join(','),
        monthly_profile: initial_monthly.join(','),
      },
      heat_information: [],
      existing_generators: [],
    },
  });

  const { isLocked } = useFormLock({ control: form.control });

  const onNext = useCallback(
    (d: Record<string, string | Record<string, any>>) => {
      if (page >= pages.length - 1) {
        return;
      }

      const nextStep = CURRENT_CONFIG.skipRules[currentPage.name as keyof (typeof CURRENT_CONFIG)['skipRules']]?.(d);

      setPage(prev => {
        setPageHistory(p => (p.at(-1) !== prev ? [...p, prev] : p));
        return nextStep > 0 ? nextStep : prev + 1;
      });
    },
    [page, currentPage],
  );

  const onPrev = useCallback(() => {
    if (pageHistory.length) {
      setPage(pageHistory.at(-1)!);
      setPageHistory(prev => prev.slice(0, -1));
    }
  }, [pageHistory]);

  const progress = (100 * page) / pages.length + 1;

  const onSubmit = useCallback(
    async (data: Record<string, string | Record<string, any>>) => {
      const { uuid, transient, load_profile_upload, heat_information, existing_generators, ...restData } = data;

      // Send data on the final step
      if (page >= pages.length - 1) {
        const models = Object.keys(restData);

        await Promise.all([
          ...models.map(m => {
            const model = restData[m];

            return sendModels(m, { ...(typeof model !== 'string' && model), uuid });
          }),
          ...(heat_information as Record<string, string | number>[])?.map(
            ({
              cost_unit_heat,
              id_heat,
              source_heat,
              total_consumption_heat,
              unit_heat,
              isGenerateProcessHeat,
              ...rest
            }) => {
              return sendModels('heat_information', {
                cost_unit_heat,
                id_heat,
                source_heat,
                total_consumption_heat,
                unit_heat,
                uuid,
                ...(isGenerateProcessHeat && rest),
              });
            },
          ),
          ...(existing_generators as Record<string, string | number>[])?.map(
            ({ isUploadFiles, gen_load_profile_upload_second, gen_load_profile_upload, ...rest }) => {

              return sendModels('existing_generators', { uuid, ...rest });
            },
          ),
        ]);

        if (reportFileGenerationEnabled) {
          await generateReport(uuid);
        }

        return;
      }

      onNext(data);
    },
    [page],
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        form.handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [page]);

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        {isFetched ? (
          <Report error={modelsError || reportError} reportUrl={report?.url} />
        ) : (
          <div className="flex flex-col min-h-dvh max-h-dvh overflow-y-scroll relative">
            <MainContainer>
              <SurveySubtitle />
              <CategoryTitle qns={currentPage.qns} category={currentPage.category} name={currentPage.name} />
              <Form inputs={currentPage.qns} />
            </MainContainer>

            <footer className="flex w-full min-h-[90px] bg-white shadow-lg footer-border-top sticky bottom-0 px-10 max-md:px-4">
              <GridContainer className="m-auto w-full h-full content-center gap-3 max-md:gap-x-0 max-md:grid-cols-2">
                <div className="justify-self-start max-md:col-span-1  max-md:row-start-2 max-md:row-span-1">
                  <button
                    className={`btn-go-back ${theme !== 'arup' ? 'btn-secondary btn-width' : 'btn-black'}`}
                    onClick={onPrev}
                    color="secondary"
                    type="button"
                    disabled={isLocked}
                    {...(page === 0 && {
                      style: {
                        visibility: 'hidden',
                      },
                    })}
                  >
                    {theme !== 'arup' ? (
                      <div className="flex gap-1 items-center">
                        <ChevronBack />
                        <p>Zurück</p>
                      </div>
                    ) : (
                      <ArrowLeft />
                    )}
                  </button>
                </div>
                <div className="justify-self-center w-full max-w-[560px] max-md:col-span-full max-md:max-w-full">
                  <ProgressBar progressLength={pages.length - 1} progress={progress} />
                </div>
                <div className="justify-self-end max-md:col-span-2 max-md:row-start-2 max-md:row-span-1">
                  <button className="btn-next next" type="submit" disabled={loadingModels || isLocked}>
                    {theme !== 'arup' ? (
                      <p>Weiter</p>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <p>Weiter</p>
                        <ArrowRight />
                      </div>
                    )}
                  </button>
                </div>
              </GridContainer>
            </footer>
          </div>
        )}
      </FormProvider>
    </form>
  );
}

export default App;
