import React, { FC, memo, ReactNode } from 'react';
import UploadFileLogo from '@components/logos/UploadFileLogo';
import RemoveLogo from '@components/logos/RemoveLogo';
import IconButton from '@components/IconButton';
import InputError from '@components/form/InputError';
import mime from 'mime';
import PdfFileLogo from '@components/logos/PdfFileLogo';
import { filesize } from 'filesize';
import CloseLogo from '@components/logos/CloseLogo';
import useFileUploader from '@hooks/useFileUploader';
import { useFormContext } from 'react-hook-form';
import LinearProgress from '@components/LinearProgress';

const ICONS_MAPPER: Record<string, ReactNode> = {
  pdf: <PdfFileLogo />,
};

interface Props {
  file: File;
  error?: { message: string } | null;
  onRemove?: () => void;
  onCancel?: () => void;
  model: string;
  upload_id?: string;
  onError?: (message: string) => void;
  onSuccess?: () => void;
  uploaded?: boolean;
}

const FilePreview: FC<Props> = ({
  model,
  error,
  file,
  onRemove,
  uploaded,
  onCancel,
  onError,
  upload_id,
  onSuccess,
}) => {
  const ext = mime.getExtension(file.type) as string;
  const { getValues, watch } = useFormContext();
  const uuid = getValues('uuid');

  const {
    data,
    loading,
    error: responseError,
  } = useFileUploader({
    file,
    uuid,
    model,
    upload_id,
    skip: !!error || uploaded,
    onError,
    onSuccess,
  });

  const errorMessage = responseError || error?.message;

  return (
    <div onClick={e => e.stopPropagation()} className="flex  gap-2 bg-white rounded-lg	p-4">
      {(ext && ICONS_MAPPER[ext]) || <UploadFileLogo className="size-10 min-w-10" />}
      <div className="flex-grow">
        <div className="mb-2 flex items-center gap-2">
          <div className="flex-grow break-all">
            <p className={`text-accent text-[16px] font-medium underline break-all mb-1`}>{file.name}</p>
            <p className={`text-[var(--grey-scale-400)] text-sm font-medium ${errorMessage ? 'mb-1' : ''}`}>
              {filesize(file.size, { standard: 'jedec' })}
            </p>
            {!!errorMessage && <InputError error={errorMessage} />}
            {!!data?.message && !errorMessage && <p className="text-success">{data.message}</p>}
          </div>

          {loading || errorMessage ? (
            <IconButton className="self-start" type="button" onClick={onCancel}>
              <CloseLogo />
            </IconButton>
          ) : (
            <IconButton type="button" onClick={onRemove}>
              <RemoveLogo />
            </IconButton>
          )}
        </div>
        {loading && <LinearProgress />}
      </div>
    </div>
  );
};

export default memo(FilePreview);
