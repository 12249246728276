import React, { FC, memo } from 'react';
import LoadProfileDailyField from '@components/custom-flows/load-profile/LoadProfileDailyField';
import LoadProfileMonthlyField from '@components/custom-flows/load-profile/LoadProfileMonthlyField';
import { Field } from '@/types';
import HeatSource from '@components/custom-flows/heat-source/HeatSource';
import GeneratorsSource from '@components/custom-flows/generators-source/GeneratorsSource';

const FIELDS_MAPPING = {
  daily_profile: LoadProfileDailyField,
  monthly_profile: LoadProfileMonthlyField,
  heat_information: HeatSource,
  existing_generators: GeneratorsSource,
};

const CustomField: FC<Field> = ({ type, ...props }) => {
  const Component = FIELDS_MAPPING[props.name as keyof typeof FIELDS_MAPPING];

  if (!Component) return <div>Unknown type {type}</div>;

  return <Component type={type} {...props} />;
};

export default memo(CustomField);
