import React, { FC, PropsWithChildren } from 'react';
import ExpandArrow from '@components/logos/ExpandArrow';
import classNames from 'classnames';

interface Props {
  title?: string;
  open?: boolean;
  onToggle?: () => void;
}

const AccordionItem: FC<PropsWithChildren<Props>> = ({ children, title, onToggle, open }) => {
  return (
    <div className={classNames('bg-white rounded-md  transition-all', open ? 'p-8' : 'py-4 px-8')}>
      <button
        onClick={onToggle}
        type="button"
        className="flex rounded-md items-center gap-3 justify-between w-full text-base text-gray-500 font-semibold bg-white"
      >
        <span className="truncate">{title}</span>
        <ExpandArrow className={classNames('*:stroke-accent', { 'rotate-180': open })} />
      </button>

      <div className={classNames('pt-4 rounded-md', { hidden: !open })}>{children}</div>
    </div>
  );
};

export default AccordionItem;
