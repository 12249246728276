import { handleMouse } from '@components/custom-flows/load-profile/helpers';
import { FC, memo } from 'react';

interface Props {
  onChange: (v: string) => void;
  profile_name: string;
  profile: number[];
  text_labels: (string | number)[];
}

const LoadProfileMonthWidget: FC<Props> = ({ onChange, profile_name, profile, text_labels }) => {
  const svg_id = profile_name + '_svg';
  const nc = profile.length;
  const cw = 360 / nc;
  const cr = cw / 2;
  const ct = cw / 3;

  return (
    // note: the image is 360x130, but the viewport -3 and +3 on the x axis avoid cutting the bubbles
    <svg id={svg_id} className="max-sm:min-w-[400px]" xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 363 130">
      <line x1="0" y1="115" x2="355" y2="115" stroke="#E2E8F0" strokeWidth="2" />
      <g>
        {profile.map((v, i) => (
          <rect
            key={i}
            x={i * cw}
            y={15}
            width={cw / 1.2}
            height="100"
            fill="white"
            stroke="#E2E8F0"
            strokeWidth="0.5"
            rx="7"
            ry="7"
          />
        ))}

        {profile.map((v, i) => (
          <rect
            key={i}
            x={i * cw}
            y={115 - v}
            width={cw / 1.2}
            height={v}
            rx="2"
            ry="2"
            fill="var(--graph-column-color"
          />
        ))}

        {profile.map((v, i) => (
          <rect
            key={i}
            x={i * cw + (cw / 1.2 - cr * 2) / 2}
            y={118 - v - cr / 2}
            width={cr * 2}
            height={cr}
            rx="8"
            ry="8"
            fill="var(--bar-color)"
          />
        ))}

        {profile.map((v, i) => (
          <rect
            key={i}
            x={i * cw + (cw / 1.2 - cr * 2) / 2}
            y={115 - v - cr / 2}
            width={cr * 2}
            height={cr}
            rx="8"
            ry="8"
            fill="oklch(var(--b1))"
            stroke="#E2E8F0"
            strokeWidth="0.5px"
          />
        ))}

        {profile.map((v, i) => (
          <text
            key={i}
            x={i * cw + 13}
            y={116 - v}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="black"
            className="text-[10px] font-bold"
          >
            {text_labels[i]}
          </text>
        ))}

        {/* invisible rectangle to click on */}
        {profile.map((v, i) => (
          <rect
            key={i}
            opacity={0}
            x={i * cw}
            y={0}
            width={cw}
            height="130"
            onClick={e => handleMouse(e, i, svg_id, onChange, profile)}
            onMouseMove={e => e.buttons == 1 && handleMouse(e, i, svg_id, onChange, profile)}
          />
        ))}
      </g>
    </svg>
  );
};

export default memo(LoadProfileMonthWidget);
