// Generated  Wed 14 Aug 2024 13:04:10 CEST
import { HEAT_UNITS } from '@/constants';
export default [

    {
        "page": "adrress_pre_collection",
        "model": "address_information",
        "name": "email",
        "type": "email",
        "is_required": true,
        "category": "Wohin dürfen wir Ihre Ergebnisse senden?",
        "label": "Email",
        "description": "Zu dieser Adresse senden wir Ihnen die Ergebnisse Ihrer Analyse. Wir geben Ihre Daten nicht an Dritte weiter und verwenden sie ausschließlich für die Analyse.",
    },
    {
        "page": "adrress_pre_collection",
        "model": "meta_information",
        "name": "dsgvo",
        "type": "switch",
        "is_required": true,
        "label": "Ich stimme den DSGVO Bestimmungen zu.",
        "description": "Unsere Datenschutzbestimmungen finden Sie auf https://www.gridty.com/datenschutz. Alle Daten werden von uns immer verschlüsselt übertragen und ausschliesslich auf Servern in Deutschland gespeichert.",
    },
    {
        "page": "total_consumption_el",
        "model": "electricity_information",
        "name": "total_consumption_el",
        "type": "integer",
        "is_required": true,
        "placeholder": "1-50000000",
        "unit": "kWh",
        "category": "Jährlicher Verbrauch",
        "label": "Wie hoch ist Ihr jährlicher Strombedarf",
        "description": "Ihr jährlicher Gesamtenergieverbrauch an Elektrizität in kWh.",
        "min": 1.0,
        "max": 50000000.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "total_consumption_el",
        "model": "electricity_information",
        "name": "av_price_el",
        "type": "float",
        "is_required": true,
        "placeholder": "1-100",
        "unit": "Cent / kWh",
        "category": "Energiepreis",
        "label": "Wie hoch ist ihr durchschnittlicher Strompreis?",
        "description": "Ihr durchschnittlicher Gesamtstrompreis (inklusive aller Zulagen) in Cent/kWh. Sollte sich dieser in nächster Zeit stark ändern, geben Sie gerne bereits den erwarteten Wert an.",
        "min": 1.0,
        "max": 100.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },

    {
        "page": "has_loadprofile",
        "model": "transient",
        "name": "has_loadprofile",
        "type": "checkbox",
        "is_required": false,
        "category": "Energieprofil",
        "label": "Haben Sie einen 15-Minütigen Lastgang für Ihren Energiebezug? (Excel oder CSV)"
    },
    {
        "page": "load_profile_upload",
        "model": "load_profile_upload",
        "name": "load_profile_upload",
        "type": "file",
        "is_required": true,
        "category": "Energieprofil",
        "label": "Bitte laden Sie hier Ihren Lastgang des Vorjahres (01.01 - 31.12.) hoch",
        "description": "Mit Ihrem Lastgang können wir beispielsweise genaue Abschätzungen zum Eigenverbrauch einer PV-Anlage, den Potentialen von Batteriespeichern uvm. machen",
        "max_files": 1, // any number, Infinity by default
        "max_size": 50, // in MB, 50 by default
        "multiple": false, // false by default
        "accept": "xlsx, csv", // 'csv' by default
        "upload_id": "loadprofile_from_grid"
    },
    {
        "page": "load_profile",
        "model": "load_profile",
        "name": "daily_profile",
        "type": "custom",
        "is_required": false,
        "category": "Energieprofil",
        "label": "Lastprofil über den Tag",
        "description": "Wir wissen: Nicht jeder Ihrer Produktionstage ist immer gleich. Hier geht es uns mehr darum, zu verstehen, wie Sie Energie über einen durchschnittlichen Tag hinweg benötigen. Es ist hier also keine Perfektion benötigt, nur eine grobe Abschätzung."
    },
    {
        "page": "load_profile",
        "model": "load_profile",
        "name": "monthly_profile",
        "type": "custom",
        "is_required": false,
        "category": "Energieprofil",
        "label": "Lastprofil über das jahr",
        "description": "Betriebsferien, Weihnachtsurlaub, Schwankungen in Ihrem Produktionsbetrieb? Hier wollen wir erfahren, wir viel Sie über das Jahr produzieren. Die Standardeinstellung ist hier Montag-Freitag, also 5 von 7 Tagen (= 71.5% der Zeit). Auch hier ist keine Perfektion gefragt, nur eine grobe Abschätzung. "
    },
    {
        "page": "knows_grid_connection",
        "model": "transient",
        "name": "knows_grid_connection",
        "type": "checkbox",
        "is_required": false,
        "category": "Netzanschluss",
        "label": "Kennen Sie Ihre Maximale Netzanschlussleistung in kW? (Wichtig für Batteriespeicher)",
    },
    {
        "page": "grid_connection_power",
        "model": "electricity_information",
        "name": "grid_connection_power",
        "type": "float",
        "is_required": false,
        "placeholder": "1-1000000",
        "unit": "kW",
        "category": "Netzanschluss",
        "label": "Netzanschlussleistung",
        "description": "Ihre Netzanschlussleistung ermöglicht beispielsweise Einschätzungen zu Ihren Potentialen mit Batteriespeichern in dynamischen Stromnetzen extra zu profitieren.",
        "min": 1.0,
        "max": 1000000.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "postcode_asset",
        "model": "meta_information",
        "name": "postcode_asset",
        "type": "text",
        "is_required": true,
        "placeholder": "z.B. 71111",
        "category": "Ort der Analyse",
        "label": "Postleitzahl des Standorts",
        "description": "Ihre Postleitzahl hilft uns, die richtigen Förderprogramme zu finden und die Potentiale erneuerbarer Energien genauer abzuschätzen.",
        "min": 1.0,
        "max": 100000.0,
        "min_err": "Die niedrigste Postleitzahl ist 01067 in Deutschland.",
        "max_err": "Die höchste Postleitzahl ist 99998 in Deutschland.",
        "pattern": "^[0-9]{5}$",
        "pattern_err": "Stellen Sie sicher, dass Sie eine korrekte Postleitzahl eingeben."
    },
    {
        "page": "headcount",
        "model": "meta_information",
        "name": "headcount",
        "type": "integer",
        "is_required": true,
        "placeholder": "1-10000",
        "category": "Ihr Unternehmen",
        "label": "Anzahl Mitarbeiter",
        "description": "Bestimmte Abschätzungen können wir über die Anzahl der Mitarbeiter:innen treffen.",
        "min": 1.0,
        "max": 100000.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "company_sector",
        "model": "meta_information",
        "name": "company_sector",
        "type": "select",
        "is_required": true,
        "placeholder": "Bitte wählen Sie",
        "category": "Ihr Unternehmen",
        "label": "Industriesektor",
        "description": "Einige unserer Abschätzungen sind sektorspezifisch. Bitte wählen Sie hier einen Sektor. "
    },
    {
        "page": "is_heat_required",
        "model": "transient",
        "name": "is_heat_required",
        "type": "checkbox",
        "is_required": false,
        "category": "Wärmeversorgung",
        "label": "Wünschen Sie auch eine Analyse Ihrer Wärmeversorgung?"
    },
    {
        "page": "heat_information",
        "model": "heat_information",
        "name": "heat_information",
        "type": "custom",
        "is_required": true,
        "category": "Wärmeversorgung"
    },
    {
        "page": "area_of_building",
        "model": "further_information",
        "name": "area_of_building",
        "type": "integer",
        "is_required": true,
        "placeholder": "10-1000000",
        "unit": "m²",
        "category": "Gebäudenutzfläche",
        "label": "Wie groß ist die beheizte Nutzfläche Ihres Gebäudes?",
        "description": "Geben Sie hier eine Abschätzung Ihrer beheizten Nutzfläche an. Dies hilft uns die Erneuerung Ihres Heizungssystems und eine möglicherweise notwendige energetische Sanierung zu analysieren.",
        "min": 0.0,
        "max": 1000000.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "has_existing_generators",
        "model": "transient",
        "name": "has_existing_generators",
        "type": "checkbox",
        "is_required": false,
        "label": "Haben Sie Erzeugungsanlagen? Für eine genaue Abschätzung benötigen wir Informationen zu weiteren Erzeugungseinheiten wie Beispielsweise einer PV-Anlage",
        "category": "(PV-) Bestandsanlagen"
    },
    {
        "page": "existing_generators",
        "model": "existing_generators",
        "name": "existing_generators",
        "type": "custom",
        "is_required": true,
        "category": "Stromerzeugungsanlagen (PV, BHKW, etc.)",
    },
    {
        "page": "has_add_area",
        "model": "transient",
        "name": "has_add_area",
        "type": "checkbox",
        "is_required": false,
        "category": "Weitere Flächen",
        "label": "Haben Sie zusätzliche Flächen für PV-Anlagen ",
        "description": "Geben Sie hier vorhandene Dach- oder Freiflächen für die Nutzung von Solarthermie- oder PV-Anlagen an. Achten Sie darauf, dass diese Flächen nicht verschattet sind. Die Flächen müssen nicht unbedingt zusammenhängend belegbar sein. Eine Abschätzung reicht uns hier."

    },
    {
        "page": "add_area_roof_pv",
        "model": "further_information",
        "name": "add_area_roof_pv",
        "type": "integer",
        "is_required": true,
        "placeholder": "10-100000",
        "unit": "m²",
        "category": "Weitere Flächen",
        "label": "Haben Sie zusätzliche Flächen für PV-Anlagen?",
        "description": "Geben Sie hier vorhandene Dach- oder Freiflächen für die Nutzung von Solarthermie- oder PV-Anlagen an. Achten Sie darauf, dass diese Flächen nicht verschattet sind. Die Flächen müssen nicht unbedingt zusammenhängend belegbar sein. Eine Abschätzung reicht uns hier.",
        "min": 0.0,
        "max": 100000.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "wants_charging",
        "model": "transient",
        "name": "wants_charging",
        "type": "checkbox",
        "is_required": false,
        "category": "Ladesäulen",
        "label": "Haben Sie Interesse an Ladesäulen? ",
        "description": "Mit Ladesäulen können Sie nicht nur Geld sparen sondern Teilweise auch dazuverdienen."
    },
    {
        "page": "has_public_parking",
        "model": "transient",
        "name": "has_public_parking",
        "type": "checkbox",
        "is_required": false,
        "category": "Ladesäulen",
        "label": "Haben Sie einen öffentlich zugänglichen Parkplatz nah an einem Wohngebiet oder einer Schnellstraße? ",
        "description": "Nah an Wohngebieten oder einer Schnellstraße/Autbahn gibt es oft Bedarf an Ladeinfrastruktur. Indem Sie Ladepunkte öffentlich zugänglich machen können sich die Säulen häufig deutlich schneller amortisieren."
    },
    {
        "page": "public_charging",
        "model": "further_information",
        "name": "count_normal_public_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl öfftl. reguläre Ladesäule",
        "description": "Nah an Wohngebieten können reguläre (bis zu 22 kW) öffentliche Ladesäulen sich schnell amortisieren. Zum Beispiel, indem Autos über Nacht geladen werden können. Sie selber können dabei bestimmen, wann genau die Ladesäule auch öffentlich zugängig sein soll. ",
        "min": 0.0,
        "max": 100.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "public_charging",
        "model": "further_information",
        "name": "count_fast_public_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl öfftl. Schnellladesäule",
        "description": "Nah an Schnellstraßen und Autobahnen können Schnellladesäulen (mit Ladeleistungen über 50 kW) sich schnell amortisieren. Dies liegt daran, dass Sie eine hohe Nachfrage durch Reisende bedienen und Elektrofahrzeuge in kurzer Zeit aufladen können. Sie selber können dabei bestimmen, wann genau die Ladesäule auch öffentlich zugängig sein soll.",
        "min": 0.0,
        "max": 100.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "private_charging",
        "model": "further_information",
        "name": "count_normal_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl reguläre Ladesäule",
        "description": "Vor allem für Ihre Mitarbeiterinnen und Mitarbeiter und Ihren internen Fuhrpark kann sich die Investition in interne reguläre (bis zu 22 kW) Ladeinfrastruktur lohnen. Als Rechenbeispiel: Bis 2030 wird erwartet, dass ca. 20 % der Autos batterieelektrisch betrieben sind. Bei 100 Autos auf Ihrem Parkplatz wollen also Maximal 20 geladen werden. An einer Ladesäule können maximal 2 Autos gleichzeitig geladen werden. Es werden also maximal 10 Ladesäulen benötigt. Unser Tipp: Eher etwas konservativ starten und nach und nach ausbauen.",
        "min": 0.0,
        "max": 100.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "private_charging",
        "model": "further_information",
        "name": "count_fast_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl Schnellladesäule",
        "description": "Interne Schnellladesäulen sind vor allem interessant, wenn Sie (größere) Nutzfahrzeuge damit laden wollen. Ein e-LKW kann beispielsweise in 4-8 Stunden geladen werden. Aber auch kleine Transporter die mehrfach am Tag längere Strecken zurücklegen müssen, können ein Grund für eine Investition sein. Leitfrage: Haben oder planen Sie elektrische Transporter oder LKW? Wenn Sie sich noch unsicher sind, geben Sie doch einfach erstmal eine Abschätzung ein. ",
        "min": 0.0,
        "max": 100.0,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "company_name",
        "type": "text",
        "is_required": true,
        "category": "Unternehmen & Ansprechpartner",
        "label": "Unternehmen"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "firstname",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Vorname"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "lastname",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Nachname"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "telephone",
        "type": "tel",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Telefon"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "notes",
        "type": "text",
        "is_required": false,
        "category": "Kontaktdaten",
        "label": "Möchten Sie uns noch etwas mitteilen?"
    }
]