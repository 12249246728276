import { ComponentProps, FC, ForwardedRef, forwardRef, memo } from 'react';
import classNames from 'classnames';

const Switch: FC<ComponentProps<'input'>> = forwardRef(
  ({ className, ...props }, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <label className={classNames('inline-flex items-center cursor-pointer ', { [className!]: className })}>
        <input ref={ref} type="checkbox" className="sr-only peer" {...props} />
        <div className="relative w-[49px] h-[26px] bg-[var(--grey-scale-400)] rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full  after:content-[''] after:absolute peer-checked:after:start-[6px] after:top-[3px] after:start-[3px] after:bg-white  after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[var(--theme-icon-color)]" />
      </label>
    );
  },
);

export default memo(Switch);
