// 24 hours 0..23
export const INITIAL_DAILY = [
  10, 10, 10, 10, 10, 10, 10, 40, 80, 90, 90, 90, 90, 90, 90, 90, 90, 90, 50, 10, 10, 10, 10, 10,
];
// 12 months 0..11
export const INITIAL_MONTHLY =
  // J    F    M    A    M    J   J   A    S    O    N   D
  [100, 100, 100, 100, 100, 100, 50, 20, 100, 100, 100, 70];

export const HEAT_UNITS = ['kWh', 'liter', 'm³', 'kg'];
