import React, { ComponentProps, FC, memo } from 'react';

const ExpandArrow: FC<ComponentProps<'svg'>> = props => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 8.25L12 15.75L4.5 8.25"
        stroke="#94A3B8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(ExpandArrow);
